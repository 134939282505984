<template>
  <v-container fluid> </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",

  created() {
    this.authLogout()
      .then(() => {
        this.$router.replace({ name: "home" });
      })
      .catch((e) => this.alertError(this.$t("Auth.logoutError") + this.$helper.error(e)));
  },

  methods: {
    ...mapActions({
      authLogout: "auth/logout",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
    }),
  },
};
</script>
